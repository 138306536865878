<template>
    <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
		<div id="page-content" v-if="!viLoader" >
      <!-- <div class="dispflex" style="align-items: baseline">
        <h1 class="hdrtitle ml-2" style="margin-top: 0px;">Fees Management</h1>
		 <button
                    type="button"
                    id="prevBtn"
                    class="backbtn"
                   @click="previousRoute"
                  >
                   <i class="fa fa-arrow-left"></i>
                  </button>
      </div> -->
      <div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Fees Management
       </h4>
      </div>
      <div class="row"> 
        <div class="col-md-12 col-lg-12 col-sm-12">
          <div class="widjet m-2">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 12px"
                  ><i class="fas fa-receipt"></i
                ></span>
                <h1>View Adhoc Fees</h1>
              </div>
			  <div style="margin-top:10px"> <input type="text" v-model="searchWords" placeholder="Search Adhoc Fee" /> </div>
              <div class="" style="width: 20%">
                <span class="has-float-label">
                  <select
				    @change="getSelectAcademicYear"
					class="form-control form-input"
					id="deptlistid"
					v-model="selectAcademicYear"
					>
					<option :value=null>--Select--</option>
					<option v-for="(item,index) in academicYearList" :key="index" :value="item._id">
						{{ item.from}} - {{ item.to }}
					</option>
					</select>
					<label for="acyearid">Academic Year <span class="required">*</span></label>
                </span>
              </div>
			  <div class="mt-1" style="margin-right: 16px;">
                
                <button class="btn gry_bg tooltipt mpopup twoicn" v-b-modal.crtfeeplan>
                   <i class="fas fa-file-invoice user1"></i>
                          <p class="user2"><i class="fas fa-plus"></i></p>
                        <span class="tooltiptext">Create Adhoc Fees</span>
                </button>
              </div>
            </div>
            <div class="widjetcontent">
            
                <div class="crtfeeplancont" style="padding: 0 13px">
                  <div
                    class="widjethdr dispflex"
                    style="align-items: baseline; padding: 10px 0"
                  >
                    <div style="width: auto">
                      <h1 style="margin: 0">Adhoc Fees</h1>
                    </div>
					<!-- <div style="margin-top:10px"><input type="text" v-model="searchWords" placeholder="Search Adhoc Fee plans"></div>
                    <div class="mr-1">
                     
                      <button
                        class="btn gry_bg tooltipt mpopup twoicn"
                        v-b-modal.crtfeeplan
                      >
                        <i class="fas fa-file-invoice user1"></i>
                          <p class="user2"><i class="fas fa-plus"></i></p>
                        <span class="tooltiptext">Create Adhoc Fees</span>
                      </button>
                    </div> -->
                  </div>
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell">Fee Plan Name</div>
					  <div class="cell">Status</div>
                      <div class="cell">Billing Date </div>
                      <div class="cell">Due Date</div>
					 <div class="cell">Last Modified By</div>
                     <div class="cell">Last Modified Date</div>
                      <div class="cell">Action</div>
                    </div>
                    <div class="resrow"
                      v-for="(plan, index) in adhocFeeList"
                      :key="index"
                    >
                     
                      <div  class="cell" data-title="Plan Name">
						  <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRoute(`/feesManagement/plan/adhocfee/${plan._id}/view`)"
                                >{{ plan.planName }} </a>
								</div>
					  <div  v-if="plan.assignedLineItem.length == 0" class="cell" style="color: brown;" data-title="Status">Draft</div>
					   <div  v-else class="cell" style="color: green;" data-title="Status">Active</div>

                      <div class="cell" data-title="Role">
                        {{ plan.billingDate | dateFormat }}
                      </div>
                     

                      <div  class="cell" data-title="feesEndDate">
                        {{ plan.dueDate | dateFormat }}
                      </div>
					   <div class="cell" data-title="Department Name">
                        {{ plan.updatedBy ? plan.updatedBy.firstName + " " +  plan.updatedBy.lastName : ''  }}
                      </div>
                      <div  class="cell" data-title="Role">
                            {{ plan.updatedDate | dateFormat }}
                          </div>
                      <div class="cell" data-title="Action">
                       
                        <button
                          @click.prevent="
                            redirectRoute(`/feesManagement/plan/adhocfee/${plan._id}/edit`)
                          "
                          class="btn round orange tooltipt"
                        >
                          <i class="fas fa-edit"></i
                          ><span class="tooltiptext">Edit</span>
                        </button>
                         <button
                          @click.prevent="
                            redirectRoute(`/feesManagement/assignAdhocFess/${plan._id}`)
                          "
                          class="btn round blue tooltipt twoicn"
                        >
                         <i class="fas fa-user"></i>
                         
                          <span class="tooltiptext">Assign students</span>
                        </button>
                        <button class="btn round red tooltipt" @click.prevent="showDeleteModal(index)">
                          <i class="far fa-trash-alt"></i
                          ><span class="tooltiptext">Delete</span>
                        </button>
                        
                      </div>
                    
                    </div>
                  </div>
                </div>
				 <div class="text-center mt-4" v-if="adhocFeeList.length > 0">
                <div class="pagination" id="pagination">
                  <a  :style="currentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToPreviousPage">&laquo;</a>
                  <!-- class="active"  -->
                  <a
                    v-for="(item, index) in lastPageNumber"
                    :key="index"
                    v-on:click="selected = item"
                    v-bind:class="{ active: selected == item }"
                    @click="navToSelectedPage(item)"
                    >{{ item }}</a
                  >
                  <a  :style="currentPageNumber === lastPageNumber ? 'pointer-events: none;' : ''" @click="goToNextPage">&raquo;</a>
                </div>
              </div>
                <!--<div class="widjetfooter text-right dk_iconsml mt-3">
                  <button
                    type="button"
                    id="prevBtn"
                    class="btn btnsml"
                   @click="previousRoute"
                  >
                    <i class="fa fa-arrow-left"></i> Back
                  </button>
                </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    <b-modal
      id="crtfeeplan"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
    <button type="button" class="close"  @click.prevent="closePreview">×</button>
      <div class="contact-form">
		  <!-- <span class="close" @click.prevent="closePreview">&times;</span> -->
        <h2>Create Adhoc Fee Plan</h2>
        <br />
        <div>
         <form
            data-vv-scope="crtAdhocFeeValidate"
            id="crtfeeplans"
            accept-charset="utf-8"
          >
		    	
				<div class="row mt-2">	
					<div class="col-md-12 col-lg-12 col-sm-12">
						<div class="widjet m-2">
							<div class="widjetcontent mt-1 p-3">
								<div class="form-row ">
									
									<div class="form-group input-group col-md-6 mt-3  pr-md-3">						
										<span class="has-float-label">
											<input type="text" name="fpName" v-validate="'required'" v-model="adhocFess.planName" class="form-control form-input" id="fpNameid"  >
											<label
													v-if="errors.first('crtAdhocFeeValidate.fpName')"
													class="vi-error"
													style="top: -1em"
													>Adhoc Fee Name is required</label
												>
												<label v-else for="fpnameid" >Adhoc Fee Name <span class="required">*</span></label>
											
										</span>
									</div>
									<div class="form-group input-group col-md-6 mt-3 ">						
										<span class="has-float-label">
											 <select
										 	   @change="setPlanDate(adhocFess.academicYear)"
												v-model="adhocFess.academicYear"
												class="form-control form-input"
												id="acyearid"
												v-validate="'required'"
												name="acdyr"
											>
												<option :value="null">-- Select --</option>
												<option
												v-for="(item, index) in academicYearList"
												:key="index"
												:value="item._id"
												>
												{{ item.from }} - {{ item.to }}
												</option>
											</select>
											<label
													v-if="errors.first('crtAdhocFeeValidate.acdyr')"
													class="vi-error"
													style="top: -1em"
													>Academic Year is required</label
												>
											<label v-else for="acyearid"
												>Academic Year <span class="required">*</span></label
											>
											
										</span>
									</div>
									<div class="form-group input-group col-md-6 mt-3  pr-md-3">						
										<span class="has-float-label">
											<input type="date" :disabled="!adhocFess.academicYear" onkeydown="return false" name="billdate"  :min="minPlanDate" :max="maxPlanDate" v-validate="'required'" v-model="adhocFess.billingDate"  class="form-control form-input" id="billdateid"  >
											<label
													v-if="errors.first('crtAdhocFeeValidate.billdate')"
													class="vi-error"
													style="top: -1em"
													>Billing Date is required</label
												>
											<label v-else for="billdateid">Billing Date <span class="required">*</span></label>
										</span>
									</div>
									
									<div class="form-group input-group col-md-6 mt-3">						
										<span class="has-float-label">
											<input type="date" onkeydown="return false" :disabled="!adhocFess.billingDate" :min="adhocFess.billingDate" :max="maxPlanDate" name="duedate" v-validate="'required'" v-model="adhocFess.dueDate"  class="form-control form-input" id="duedateid" >
											<label
													v-if="errors.first('crtAdhocFeeValidate.duedate')"
													class="vi-error"
													style="top: -1em"
													>Due Date is required</label
												>
											<label v-else for="duedateid">Due Date <span class="required">*</span></label>
										</span>
									</div>
									<!-- <div class="form-group input-group col-md-12 mt-3 ">						
										<span class="has-float-label">
											<select
												v-model="adhocFess.remainder" 
												name="Due on"
												class="form-control form-input"
												id="dueonid"
											>
											<option :value=null >  -- Select --  </option>
											<option v-for="(item,index) in remainderDueDates" :key="index" :value="item.value">
												{{ item.label }}
											</option>
											</select>
											
											<label for="reminderid">Reminder </label>
										</span>
									</div> -->
									
								</div>							
							</div>
							
						</div>
					</div>
				</div>
				<div class="row mt-2">	
					<div class="col-md-12 col-lg-12 col-sm-12">
						<div class="widjet m-2">
							<div class="admintbl mt-1 p-3">
								<table border="1">
									<thead>
										<tr>
											<th scope="col" rowspan="2" class="text-center">Fee Name<span class="required">*</span></th>
											<th scope="col" rowspan="2" class="text-center">Quantity<span class="required">*</span></th>
											<th scope="col" rowspan="2">Amount($)<span class="required">*</span></th>
											<th scope="col" colspan="2" class="text-center">Discount</th>
											<th scope="col" colspan="2" class="text-center">GST</th>
											<th scope="col" rowspan="2" class="text-center">Net Amount ($)</th>
											<th scope="col" rowspan="2" class="text-center" >Action <button class="btn rndbtn" v-if="adhocFess.adhocFeeLineItem.length == 0" @click.prevent="addLineItem"><i class="fas fa-plus-circle" style="padding-top:4px;"></i></button></th>
										</tr>
										<tr>
											
											<th class="text-center">%</th>
											<th class="text-center">Amount</th>
											
											<th class="text-center">Tax Slab <span class="required">*</span></th>
											 <th class="text-center">Amount</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in adhocFess.adhocFeeLineItem" :key="index">
											<td data-label="Fee Name"><input type="text" class="form-control form-input text-center" v-validate="'required'" v-model="item.name" :name="index + 'name'" />
											<span class="required" v-if="errors.first(`crtAdhocFeeValidate.${index + 'name'}`)"> Fee Name is required</span>
											</td>
											<td data-label="Quantity"><input type="number" class="form-control form-input text-center" v-validate="'required|numeric|min_value:1'" :name="index + 'Quantity'" @change="setQuantyAndAmountvalues(index,'qty')" :disabled="!item.name" v-model="item.quantity" />
											<span class="required" v-if="errors.first(`crtAdhocFeeValidate.${index + 'Quantity'}`)"> Quantity is required(Minimum value is 1)</span>
											</td>
											<td data-label="Actual Amount ($)"><input type="number" class="form-control form-input text-center" v-validate="'required|numeric|min_value:1'" :name="index + 'Amount'" @input="setQuantyAndAmountvalues(index,'actAmount')" :disabled="!item.quantity" v-model="item.actualAmount" />
											<span class="required" v-if="errors.first(`crtAdhocFeeValidate.${index + 'Amount'}`)"> Actual Amount is required</span>
											</td>
											
											<td data-label="%"><input  class="form-control form-input text-center" v-validate="'numeric|min:0'" :name="index + 'DiscountPercentage'" @input="setdiscountAmount(index)" type="number" v-model="item.discountPercentage" />
											<span class="required" v-if="errors.first(`crtAdhocFeeValidate.${index + 'DiscountPercentage'}`)"> Minimum value is 0</span>
											</td>
											<td data-label="Amount" class="text-center">{{ item.discountAmount }}</td>
											
											<td data-label="%">
												<select
													class="form-control form-input" 
												   :name="index + 'tax'" 
												   v-validate="'required'" 
												   @change="settaxAmount(index)" 
												   v-model="item.gstTaxSlab" 
												>
													<option
													v-for="(item, index) in taxSlabList"
													:key="index"
													:value="item.value"
													>
													{{ item.label }}
													</option>
												</select>
											    <span class="required" v-if="errors.first(`crtAdhocFeeValidate.${index + 'tax'}`)"> Tax Slab is required</span>

												
											</td>

											<td data-label="Amount" class="text-center">{{ item.taxamount }}</td>
											<td data-label="Net Amount ($)" class="txtrgt">{{ item.netAmount }} </td>
											<td data-label="Action" class="text-right">
												<div style="width:76%">
                          <b-icon icon="plus-circle" variant="primary" style="height: 25px;margin-right: 5px;" v-if="adhocFess.adhocFeeLineItem.length == ( index + 1)" @click.prevent="addLineItem"></b-icon>
                          <b-icon icon="dash-circle" variant="danger" style="height: 25px" @click.prevent="removeLineItem(index)"></b-icon>

												<!-- <button class="btn rndbtn" v-if="adhocFess.adhocFeeLineItem.length == ( index + 1)" @click.prevent="addLineItem"><i class="fas fa-plus-circle" style="padding-top:4px;"></i></button>
												<button class="btn rndbtn" @click.prevent="removeLineItem(index)"><i class="fas fa-minus-circle" style="padding-top:4px;"></i></button> -->
												</div>
											</td>
										</tr>
										
										<tr>
											<td colspan="6"></td>
											<td ><b>Amount</b></td>
											<td class="txtrgt">{{ getTotalActualAmount() }}</td>
											<td ></td>
										</tr>
										<tr>
											<td colspan="6"></td>
											<td ><b>Discount (-)</b></td>
											<td class="txtrgt">{{ getTotalDiscountAmount() }}</td>
											<td ></td>
										</tr>
										<tr>
											<td colspan="6"></td>
											<td ><b>Total Tax (+)</b></td>
											<td class="txtrgt">{{ getTotalTaxAmount() }}</td>
											<td ></td>
										</tr>
										<tr style="background:#e7e7e7;color: #444444;">
											<td colspan="6"></td>
											<td style="color:#2954A2"><b>Total Amount</b></td>
											<td class="txtrgt"><b>{{ getTotalNetAmount() }}</b></td>
											<td ></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					
				</div>
				<div class="row mt-2">	
					<div class="col-md-12 col-lg-12 col-sm-12">
						<!-- <div class="widjet m-2"> -->
							
							<div class="widjetfooter text-center dkicon">							
									<button  @click.prevent="createAdhocFee" class="btn btnsml" >
								<span>Save</span></button>
									<button @click.prevent="closeCreatePopUp" type="button" class="btn btncl clsmdl">
									   <span>Cancel</span></button>
								<!-- 	<i class="fas fa-check-circle"></i>  </div> -->
						</div>
						
					</div>
				</div>
		   </form>

          <!-- <div class="dispflex mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="dk_icon">
              <button
                type="button"
                class="btn btnsml mpopup"
                title="Create Custom Field"
              >
                <i class="fas fa-plus"></i> <span>Custom Field</span>
              </button>
            </div>
            <div class="text-right dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                v-if="editId"
                @click.prevent="updateFeePlan"
              >
                  <span>Update</span>
              </button>
              <button v-else
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="createFeePlan"
              >
                  <span>Save</span>
              </button>
              
              <button
                @click.prevent="closePoup"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div> -->
        </div>
      </div>
    </b-modal>
   	<b-modal id="deleteFeeTypePopup" no-close-on-backdrop
      no-close-on-esc >
      <button type="button" class="close"  @click.prevent="closeDeletePopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
        Are you sure you want to delete this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
           @click.prevent="confirmDelete"
            type="button"
            class="btn btnsml"
            id="svbtn"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeDeletePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
      </div>
       <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
    </div>
</template>
<script>

import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "vi-adhocFees",
  data() {
    return {

      adhocFess: {
		  adhocFeeLineItem: [{
			  name: '',
			  discountAmount: null,
			  quantity: null,
			  actualAmount: null,
			  discountPercentage: null,
			  taxamount: null,
			  gstTaxSlab: null,
			  netAmount: null
		  }],
		  planName: '',
		  academicYear: null,
		  billingDate: null,
		  dueDate: null,
		  remainder: ''
	  },
	  searchWords: '',
      skip: 0, // set the skip info
      limit: 7, // set the limit info
	  currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: 0,
	  minPlanDate: null,
      maxPlanDate: null,
      minMonth: false,
      minFromPlanStartDate: null,
      classList: [],
      academicYearList: [],
	  adhocFeeList: [],
     viLoader: false,
	  allAdhocList: [],
	  selectAcademicYear: null,
	  remainderDueDates: [{
        label: '1 day',
        value: '1'
      },
      {
        label: '2 days',
        value: '2'
      },
      {
        label: '3 days',
        value: '3'
      },
      {
        label: '4 days',
        value: '4'
      },
      {
        label: '5 days',
        value: '5'
      },
      {
        label: '6 days',
        value: '6'
      },
      {
        label: '7 days',
        value: '7'
      },
      {
        label: '8 days',
        value: '8'
      },
      {
        label: '9 days',
        value: '9'
      },
      {
        label: '10 days',
        value: '10'
      },
      {
        label: '11 days',
        value: '11'
      },
      {
        label: '12 days',
        value: '12'
      },
      {
        label: '13 days',
        value: '13'
      },
      {
        label: '14 days',
        value: '14'
      },
      {
        label: '15 days',
        value: '15'
      },
      {
        label: '16 days',
        value: '16'
      },
      {
        label: '17 days',
        value: '17'
      },
      {
        label: '18 days',
        value: '18'
      },
      {
        label: '19 days',
        value: '19'
      },
      {
        label: '20 days',
        value: '20'
      },
      {
        label: '21 days',
        value: '21'
      },
      {
        label: '22 days',
        value: '22'
      },
      {
        label: '23 days',
        value: '23'
      },
      {
        label: '24 days',
        value: '24'
      },
      {
        label: '25 days',
        value: '25'
      },
      {
        label: '26 days',
        value: '26'
      },
      {
        label: '27 days',
        value: '27'
      },
      {
        label: '28 days',
        value: '28'
      },
      {
        label: '29 days',
        value: '29'
      },
      {
        label: '30 days',
        value: '30'
      },
      {
        label: '31 days',
        value: '31'
      }
      ],
	  taxSlabList: [
		  {
			  label: '0%',
			  value: "0"
		  },
		  {
			  label: '5%',
			  value: "5"
		  },
		  {
			  label: '12%',
			  value: "12"
		  },
		  {
			  label: '18%',
			  value: "18"
		  },
		  {
			  label: '28%',
			  value: "28"
		  }
	  ],
	  deleteId: null

    };
  },
  created() {
	  this.getClassList();
	  this.getAcademicYearList();
	  this.adhocFeePlanList('new');
  },
   computed: {
     styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      }
     
  },
  watch: {
          searchWords: function (currentVal, oldVal) {
               let hasCurrentParam = currentVal || '';
               let hasOldParam = oldVal || '';

              if (hasCurrentParam != hasOldParam) {
				  this.skip = 0
                   this.currentPageNumber = 1,
                   this.lastPageNumber = 1,
                   this.selected = 1,
                   this.totalCount = 0,
				   this.countAll = true,
                  this.adhocFeePlanList('old');
              }
          }
  },
  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
      let data2 = localStorage.getItem('activeTab');
     

        if(data2 == '/feesManagement/home'){
          localStorage.setItem("activeTab",'/feesManagement/home');
          localStorage.setItem("previousTab",'/feesManagement/home');
        }else{
           localStorage.setItem("activeTab",data);
          localStorage.setItem("previousTab",data);
        }
     this.$router.go(-1)
    },
	  async navToSelectedPage(selectedPageNo) {

      this.skip = (this.limit * (selectedPageNo - 1));

      this.countAll = false

      this.adhocFeePlanList('old');
    },

    async goToNextPage() {
      this.countAll = false
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));

      this.adhocFeePlanList('old');
    },

    async goToPreviousPage() {
       this.countAll = false
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));
     
      this.adhocFeePlanList('old');
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
	getAcademicYearName(id){

	  const acdemicYearDetails =  this.academicYearList.find((x) => x._id == id);
      if(acdemicYearDetails){
       return acdemicYearDetails.from + '-'  + acdemicYearDetails.to
      }else{
       return ''  
      }
     
    },
	addLineItem(){
		 this.adhocFess.adhocFeeLineItem.push({
			  name: '',
			  discountAmount: null,
			  quantity: null,
			  actualAmount: null,
			  discountPercentage: null,
			  taxamount: null,
			  gstTaxSlab: null,
			  netAmount: null
		  })
	},
	getTotalActualAmount(){
			if(this.adhocFess.adhocFeeLineItem.length > 0){
				let totalAmount = 0

				for(let x of this.adhocFess.adhocFeeLineItem){
					if(x.actualAmount){
					totalAmount = (totalAmount + Number(x.actualAmount))
				} 
				}
				return totalAmount

			}else{
				return 0
			}
	},
	getTotalDiscountAmount(){
		if(this.adhocFess.adhocFeeLineItem.length > 0){
			let totalAmount = 0

				for(let x of this.adhocFess.adhocFeeLineItem){
					if(x.discountAmount){
					totalAmount = (totalAmount + Number(x.discountAmount))
				} 
				}
				return totalAmount

		}else{
			return 0
		}
	},
	getTotalTaxAmount(){
		if(this.adhocFess.adhocFeeLineItem.length > 0){
			let totalAmount = 0

				for(let x of this.adhocFess.adhocFeeLineItem){
					if(x.taxamount){
					totalAmount = (totalAmount + Number(x.taxamount))
				} 
				}

				return totalAmount

		}else{
		return 0
		}
	},
	getTotalNetAmount(){
		

		if(this.adhocFess.adhocFeeLineItem.length > 0){

		let totalAmount = 0

        for(let x of this.adhocFess.adhocFeeLineItem){
			if(x.netAmount){
			totalAmount = (totalAmount + Number(x.netAmount))
		  } 
		}
		return totalAmount
	}else{
		return 0
	}
	},	
  
	removeLineItem(index){
		 this.adhocFess.adhocFeeLineItem.splice(index,1);
	},
	setPlanDate(acdyear){
      if(acdyear){
        const selectAcdYear = this.academicYearList.find( x => x._id == acdyear);
        
        let start = new Date(selectAcdYear.from)
        let end = new Date(selectAcdYear.to)
         // start date 
        let startmonth = start.getMonth()+1
        let startdate = start.getDate()
        

        if (startdate < 10) {
          startdate = '0' + startdate;
        }
        if (startmonth < 10) {
          startmonth = '0' + startmonth;
        }

        this.minPlanDate =  start.getFullYear() + "-" + startmonth + "-" + startdate;
       // end date

        let endmonth = end.getMonth()+1
       
		let enddate = new Date(end.getFullYear(), endmonth, 0).getDate();
		
        if (endmonth < 10) {
          endmonth = '0' + endmonth;
        }

        this.maxPlanDate =  end.getFullYear() + "-" + endmonth + "-" + enddate;

      }
    },
	setdiscountAmount(index){

		const details = this.adhocFess.adhocFeeLineItem[index]

        const quntyAddamount = (details.actualAmount * details.quantity)

		if(details.discountPercentage){
			

			const percent = (quntyAddamount / 100) * details.discountPercentage;
			this.adhocFess.adhocFeeLineItem[index].discountAmount = percent.toFixed(2);

			if(details.gstTaxSlab){
					const taxAmnt = ((quntyAddamount - percent)  / 100) * details.gstTaxSlab;
			        this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt.toFixed(2);
					this.adhocFess.adhocFeeLineItem[index].netAmount = ((details.actualAmount - percent) + taxAmnt).toFixed(2);
			}else{
               this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount - percent).toFixed(2));
			}
		}else if(details.gstTaxSlab){
			this.adhocFess.adhocFeeLineItem[index].discountAmount = null
            const taxAmnt = (quntyAddamount  / 100) * details.gstTaxSlab;
			this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt.toFixed(2);
			this.adhocFess.adhocFeeLineItem[index].netAmount = (quntyAddamount + taxAmnt).toFixed(2);
		}
		else{
		 this.adhocFess.adhocFeeLineItem[index].discountAmount = null
         this.adhocFess.adhocFeeLineItem[index].netAmount = (quntyAddamount).toFixed(2);
		}
	},
	setQuantyAndAmountvalues(index,type){

		const details = this.adhocFess.adhocFeeLineItem[index]
		
		if(type == 'qty'){
			if(details.quantity){
				if(details.quantity && details.actualAmount){
					const quntyAddamount = (details.actualAmount * details.quantity)

					if(details.quantity && details.actualAmount && !details.discountPercentage && !details.discountAmount && !details.gstTaxSlab && !details.taxamount){
                       this.adhocFess.adhocFeeLineItem[index].netAmount = ((details.quantity * details.actualAmount))

					}else if(details.quantity && details.actualAmount && details.discountPercentage && details.discountAmount && details.gstTaxSlab && details.taxamount){
					const percent = (quntyAddamount / 100) * details.discountPercentage;
					this.adhocFess.adhocFeeLineItem[index].discountAmount = percent

					const taxAmnt = ((quntyAddamount - percent)  / 100) * details.gstTaxSlab;
			        this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt
					this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount - percent) + taxAmnt)

					}else if(!details.discountPercentage && !details.discountAmount && !details.gstTaxSlab && !details.taxamount){
					  this.adhocFess.adhocFeeLineItem[index].netAmount = quntyAddamount

				   }else if(details.discountPercentage && details.discountAmount && !details.gstTaxSlab && !details.taxamount){
				    const percent = (quntyAddamount / 100) * details.discountPercentage;
					this.adhocFess.adhocFeeLineItem[index].discountAmount = percent

					this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount - percent))

				}else if(!details.discountPercentage && !details.discountAmount && details.gstTaxSlab && details.taxamount){
					const taxAmnt = (quntyAddamount  / 100) * details.gstTaxSlab;
			        this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt
					this.adhocFess.adhocFeeLineItem[index].netAmount = (quntyAddamount + taxAmnt)

				}else{
					this.adhocFess.adhocFeeLineItem[index].discountAmount = null;
					this.adhocFess.adhocFeeLineItem[index].discountPercentage = null;
					this.adhocFess.adhocFeeLineItem[index].taxamount = null;
					this.adhocFess.adhocFeeLineItem[index].gstTaxSlab = null;
					this.adhocFess.adhocFeeLineItem[index].netAmount = null
				}
				}
			}else{
				    this.adhocFess.adhocFeeLineItem[index].discountAmount = null;
					this.adhocFess.adhocFeeLineItem[index].actualAmount = null;
					this.adhocFess.adhocFeeLineItem[index].discountPercentage = null;
					this.adhocFess.adhocFeeLineItem[index].taxamount = null;
					this.adhocFess.adhocFeeLineItem[index].gstTaxSlab = null;
					this.adhocFess.adhocFeeLineItem[index].netAmount = null
			}
		}else{

			if(details.actualAmount){

				const quntyAddamount = (details.actualAmount * details.quantity)

				if(details.discountPercentage && details.discountAmount && details.gstTaxSlab && details.taxamount){

					const percent = (quntyAddamount / 100) * details.discountPercentage;
					this.adhocFess.adhocFeeLineItem[index].discountAmount = percent

					const taxAmnt = ((details.actualAmount - percent)  / 100) * details.gstTaxSlab;
			        this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt
					this.adhocFess.adhocFeeLineItem[index].netAmount = ((details.actualAmount - percent) + taxAmnt)


				}else if(!details.discountPercentage && !details.discountAmount && !details.gstTaxSlab && !details.taxamount){
					this.adhocFess.adhocFeeLineItem[index].netAmount = quntyAddamount

				}else if(details.discountPercentage && details.discountAmount && !details.gstTaxSlab && !details.taxamount){
				
				    const percent = (quntyAddamount / 100) * details.discountPercentage;
					this.adhocFess.adhocFeeLineItem[index].discountAmount = percent

					this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount - percent))

				}else if(!details.discountPercentage && !details.discountAmount && details.gstTaxSlab && details.taxamount){
                  
					const taxAmnt = (quntyAddamount  / 100) * details.gstTaxSlab;
			        this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt
					this.adhocFess.adhocFeeLineItem[index].netAmount = (quntyAddamount + taxAmnt)

				}else{

					this.adhocFess.adhocFeeLineItem[index].discountAmount = null;
					this.adhocFess.adhocFeeLineItem[index].discountPercentage = null;
					this.adhocFess.adhocFeeLineItem[index].taxamount = null;
					this.adhocFess.adhocFeeLineItem[index].gstTaxSlab = null;
					this.adhocFess.adhocFeeLineItem[index].netAmount = null
				}

			}else{

			this.adhocFess.adhocFeeLineItem[index].discountAmount = null;
			this.adhocFess.adhocFeeLineItem[index].discountPercentage = null;
			this.adhocFess.adhocFeeLineItem[index].taxamount = null;
			this.adhocFess.adhocFeeLineItem[index].gstTaxSlab = null;
			this.adhocFess.adhocFeeLineItem[index].netAmount = null
			}
		}
	},
	settaxAmount(index){
        
		const details = this.adhocFess.adhocFeeLineItem[index]

		const quntyAddamount = (details.actualAmount * details.quantity)

        if(details.gstTaxSlab){
			if(details.discountPercentage && details.discountAmount){
				const taxAmnt = ((quntyAddamount - details.discountAmount)  / 100) * details.gstTaxSlab;
				this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt.toFixed(2);

			    this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount - details.discountAmount) + taxAmnt).toFixed(2);
			}else{
				const taxAmnt = (quntyAddamount  / 100) * details.gstTaxSlab;
				this.adhocFess.adhocFeeLineItem[index].taxamount = taxAmnt.toFixed(2);
				this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount) + taxAmnt).toFixed(2);
			}
		}else{
			this.adhocFess.adhocFeeLineItem[index].taxamount = null
			if(details.discountPercentage){

				this.adhocFess.adhocFeeLineItem[index].netAmount = ((quntyAddamount - percent)).toFixed(2);

			}else{
				this.adhocFess.adhocFeeLineItem[index].netAmount = (quntyAddamount).toFixed(2);
				
			}
		}
		
	},
	 showDeleteModal(index){

      const adhocFeeDetails = this.adhocFeeList[index]
      this.deleteId = adhocFeeDetails._id
     
     this.$bvModal.show("deleteFeeTypePopup");

    },
    closeDeletePopUp(){
      this.$bvModal.hide("deleteFeeTypePopup");
      this.deleteId = null;
    },
    async confirmDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
       // this.viLoader = true;
        const response = await ViService.viDelete(`/feesManagement/deleteAdhocFeePlan/${this.deleteId}`,userData.token);

        if (response.isSuccess) {
          this.closeDeletePopUp();
		      this.adhocFeePlanList('old');
          let getCounts = this.$store.getters.getFeesManagementObjCount
			getCounts.adhocFees = getCounts.adhocFees - 1

			this.$store.dispatch('setFeesManagementObjCount', getCounts);
    
          // let getCounts = this.$store.getters.getAdmissionObjCounts
          //   getCounts.academicYear = getCounts.academicYear - 1

          //   this.$store.dispatch('setAdmissionObjCOunt', getCounts);
            
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },

	async getClassList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
         this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/class/getAllClasses?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
			const classResult = secureUI.secureGet(response.data);
			if(classResult.length > 0 ){
              for(let x of classResult){
				  this.classList.push({
					        _id: x._id,
							className: x.className,
							section: x.section,
							isChecked: false,
						
				  }) 
			  }
			}else{
				this.classList = []
			}
          this.classList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
	 async createAdhocFee() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtAdhocFeeValidate").then((result) => {
        isFormValid = result;
      });

      if(isFormValid){
        let userData = secureUI.sessionGet("user");

        if(!userData){
          this.$toasted.error("Please login and do the action");
        } else {
          if(this.adhocFess.adhocFeeLineItem && this.adhocFess.adhocFeeLineItem.length > 0){
          this.viLoader = true;
          const response = await ViService.viXPost("/feesManagement/createAdhocFee",this.adhocFess,userData.token );

          if (response.isSuccess) {
            this.closeCreatePopUp();
            this.adhocFeePlanList('old');
            this.$toasted.success(response.message);

			let getCounts = this.$store.getters.getFeesManagementObjCount
			getCounts.adhocFees = getCounts.adhocFees + 1

			this.$store.dispatch('setFeesManagementObjCount', getCounts);

			this.$router.push("/feesManagement/adhocfees");
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }else{
				this.$toasted.error('Please add minimum one adhocFeeLineItem');
			}
        }
      } else {
        this.$toasted.error(message);
      }
    },
	async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);
		 
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
	 closePreview(){
     this.$bvModal.hide("crtfeeplan");
    },
	getSelectAcademicYear(){
		if(this.selectAcademicYear){
            this.adhocFeePlanList('old');
          }else{
            this.selectAcademicYear = null
		    this.adhocFeePlanList('old');
          }

          this.skip = 0
          this.currentPageNumber = 1,
          this.lastPageNumber = 1,
          this.selected = 1,
          this.totalCount = 0,
          this.countAll = true
    },
	async adhocFeePlanList(type) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
       if(type == 'new') {
         this.viLoader = true;
       }
		    const response = await ViService.viXGet(`/feesManagement/getAdhocFeesPlanListView?skip=${this.skip}&limit=${this.limit}&search=${this.searchWords}&academicYear=${this.selectAcademicYear}&isCount=${this.countAll}`,userData.token);

        if (response.isSuccess) {

           const resultData = secureUI.secureGet(response.data);

           this.adhocFeeList = resultData.adhocFeeList

		   this.allAdhocList = resultData.allAdhocList

          if(this.countAll){
            this.totalCount = resultData.totalCount;
            this.lastPageNumber = Math.ceil(this.totalCount/this.limit);
          }

	
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false;
      }
    },
	closeCreatePopUp(){
	    this.$bvModal.hide("crtfeeplan");
		this.adhocFess.planName = '',
		this.adhocFess.academicYear = null,
		this.adhocFess.billingDate = null,
		this.adhocFess.dueDate = null,
		this.adhocFess.remainder = '',
		this.adhocFess.adhocFeeLineItem = [],
		this.adhocFess.adhocFeeLineItem.push({
			  name: '',
			  discountAmount: null,
			  quantity: null,
			  actualAmount: null,
			  discountPercentage: null,
			  taxamount: null,
			  gstTaxSlab: null,
			  netAmount: null
		  })
      
	}
  },
   components: {
      ViSpinner
    }
};
</script>